import { useEffect } from "react";
import { connect } from "react-redux";
import {
  Link as RouterLink,
  Navigate,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Box,
  Button,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import queryString from "query-string";

import PropTypes from "prop-types";
import Page from "@src/components/Page";
import { clearMessages, login } from "@src/actions/userActions";
import Loader from "@src/components/Loader";
import { useAuthentication } from "@src/services/auth.service";
import { RESPONSE_STATUS } from "@src/utils/constants";
import {
  isPasswordRegisterProhibitedOnBlur,
  isPasswordRegisterProhibited,
} from "@src/utils/helpers";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { withSnackbar } from "@src/components/SnackBarComponent";
import AppleSignInSignUpButton from "@src/components/AppleSignInSignUpButton";
import MicrosoftSignInSignUpButton from "@src/components/MicrosoftSignInSignUpButton";
import GoogleLoginRegisterButton from "@src/views/auth/GoogleLoginRegisterButton";
import { SingleSignOnButton } from "@src/components/SingleSignOnButton";

const useStyles = makeStyles(() => ({
  forgotPasswordLink: {
    textDecoration: "underline",
    textAlign: "right",
    opacity: 0.7,
  },
}));

const LoginView = ({ loginAction, authReducers, removeMessages, ...props }) => {
  const classes = useStyles();
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const { isFromCheckIn } = useParams();

  const { isLoggedIn, logout } = useAuthentication();
  const { search } = useLocation();

  const onSubmit = async (fields, { setStatus, setSubmitting }) => {
    const { email, password } = fields;
    setStatus();
    setSubmitting(true);
    if (isPasswordRegisterProhibited(email)) {
      window.location.replace(
        `${pathT("route.singleSignOn")}?IsSignUp=false&email=${email}`,
      );
    }
    await loginAction(email, password);
    setSubmitting(false);
  };

  const handleRegisterClick = () => {
    logout();
    navigate(pathT("route.register"));
  };

  useEffect(() => {
    if (authReducers.error) {
      if (authReducers.error.status === RESPONSE_STATUS.FORBIDDEN) {
        navigate(pathT("route.welcome"));
      } else {
        props.snackbarShowMessage(t(authReducers.error?.message));
      }
      removeMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [authReducers.error]);

  useEffect(() => {
    if (authReducers.twoFactorPending && !authReducers.isLoggedIn) {
      navigate(pathT("route.twoFactorCodeRequest"));
      removeMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [authReducers.twoFactorPending]);

  if (isLoggedIn) {
    const { redirect } = queryString.parse(search);
    return <Navigate to={redirect || pathT("route.account")} />;
  }

  return (
    <Page title={t("LoginView.Title")}>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t("Validations.InvalidEmail"))
            .required(t("Validations.RequiredField")),
          password: Yup.string().required(t("Validations.RequiredField")),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant={isSmallScreen ? "h3" : "h1"}>
              {isFromCheckIn
                ? t("LoginView.HeadingForCheckIn")
                : t("LoginView.Heading")}
            </Typography>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={(touched.email && errors.email) || " "}
              label={t("LoginView.Email")}
              name="email"
              margin="normal"
              onBlur={(blurEvent) => {
                isPasswordRegisterProhibitedOnBlur(blurEvent, (email) => {
                  window.location.replace(
                    `${pathT("route.singleSignOn")}?IsSignUp=false&email=${email}`,
                  );
                });
                handleBlur(blurEvent);
              }}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="standard"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={(touched.password && errors.password) || " "}
              label={t("LoginView.Password")}
              name="password"
              onBlur={handleBlur}
              value={values.password}
              variant="standard"
              type="password"
              onChange={handleChange}
            />
            {isSubmitting && <Loader />}
            <Box className={classes.forgotPasswordLink}>
              <Link
                component={RouterLink}
                to={pathT("route.forgotPassword")}
                variant="body2"
                underline="hover"
              >
                <b>{t("LoginView.ForgotPassword")}</b>
              </Link>
            </Box>
            <Button
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              <Typography
                variant="body2"
                style={{ color: "#ffffff", opacity: "100" }}
              >
                {t("LoginView.Login")}
              </Typography>
            </Button>
          </form>
        )}
      </Formik>
      <Typography variant="body2" align="center">
        {t("LoginView.Or")}
      </Typography>
      <Box className={classes.footer}>
        <div className="grid gap-3 my-3">
          <GoogleLoginRegisterButton className="px-6 py-4 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all" />
          <AppleSignInSignUpButton
            className="px-6 py-6 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
            buttonText={t("LoginView.AppleLogin")}
          />
          <MicrosoftSignInSignUpButton
            className="px-6 py-6 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
            buttonText={t("LoginView.MicrosoftLogin")}
          />
          <SingleSignOnButton
            fullWidth
            className="px-6 py-4 m-0 bg-white border-2 hover:border-2 border-gray-300 hover:border-gray-400 hover:bg-gray-50 transition-all"
            text={t("LoginView.SingleSignOn")}
            onClick={() =>
              navigate(`${pathT("route.singleSignOn")}?IsSignUp=false`)
            }
          />
        </div>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography color="textSecondary" variant="body2">
            {t("LoginView.DontHaveAccount")}{" "}
            <b>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <span
                // eslint-disable-next-line react/no-unknown-property
                underline="always"
                // eslint-disable-next-line react/no-unknown-property
                component={RouterLink}
                onClick={handleRegisterClick}
                style={{ cursor: "pointer" }}
              >
                <u>{t("LoginView.SignUp")}</u>
              </span>
            </b>
          </Typography>
        </Box>
      </Box>
    </Page>
  );
};

LoginView.propTypes = {
  loginAction: PropTypes.func.isRequired,
  removeMessages: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  authReducers: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  loginAction: (username, password) => dispatch(login(username, password)),
  removeMessages: () => {
    dispatch(clearMessages());
  },
});

const mapStateToProps = (state) => state;
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(LoginView),
);
