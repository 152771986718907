import { useEffect } from "react";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import Page from "@src/components/Page";
import { useSearchParams } from "react-router-dom";
import Loader from "@src/components/Loader";
import { ONBOARDING_VIEW_REFERRAL_STATUS } from "@src/utils/constants";
import sessionStorageService from "@src/services/sessionStorage.service";
import { useEmployers } from "@src/queries/common";
import { logout } from "@src/actions/userActions";
import WelcomePage from "./components/WelcomePage";

const OnboardingView = () => {
  const { t } = useTranslatedNavigate();

  const { data: employers, isFetching } = useEmployers();
  const activeEmployers = employers.filter((e) => e.active);

  const [queryParameters] = useSearchParams();

  // Save employer to session storage if it is part of the URL
  // This is to skip the employer selection step
  useEffect(() => {
    const referralFromStorage = sessionStorageService.getReferralFromStorage();
    const referalFromURL = queryParameters.get(
      t("route.onboarding.queryParam.employer"),
    );

    if (!activeEmployers || !referalFromURL) {
      // check if already set in session storage
      if (referralFromStorage) {
        return;
      }
    }

    const company = activeEmployers
      .filter((employer) => employer.slug != null)
      .find(
        (employer) =>
          employer.slug?.toLowerCase() === referalFromURL?.toLowerCase(),
      );

    if (!company?.id) {
      return;
    }

    sessionStorageService.saveReferralToStorage({
      referral: ONBOARDING_VIEW_REFERRAL_STATUS.COMPANY,
      companyId: company?.id,
      slug: company?.slug,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [activeEmployers]);

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Page title={t("OnBoarding.Title")}>
      <WelcomePage logout={logout} />
    </Page>
  );
};

export default OnboardingView;
